<template>
  <div style="overflow: hidden">
    <container style="border-top: 5px solid #d9a259">
      <a-row type="flex" justify="end">
        <a-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="19"
          :xl="19"
          :xxl="19"
          class="top-bar-curve"
        >
          <a-space :size="16">
            <div class="title">
              <MailFilled class="icon"/>
              info@suniversgroup.com
            </div>
            <div class="title d-none-sm" style="margin-right: 8px">
              <PhoneFilled class="icon" :rotate="90"/>
              0755 8254 2536
            </div>
          </a-space>

          <a-space :size="16" class="d-none-sm" style="float: right">
            <span class="city">深圳</span>
            <span class="city">香港</span>
            <span class="city">广州</span>
            <span class="city">成都</span>
            <span class="city">武汉</span>
            <span class="city">石家庄</span>
            <span class="city">佛山</span>
            <span class="city">珠海</span>
            <span class="city">杭州</span>
            <span class="city">上海</span>
            <span class="city">郑州</span>
            <span class="city">厦门</span>
            <span class="city">太原</span>
            昆明
          </a-space>
        </a-col>
      </a-row>
    </container>

    <container class="header" :class="{ fixed: isFixed }">
      <div style="display: flex; justify-content: space-between;align-items: center">
        <div class="logo-box">
          <a-image
            class="logo"
            :src="require('@/assets/image/logo/横版烫金.png')"
            :preview="false"
          />
        </div>

        <a-space :size="0" style="align-items: center">
          <a-button type="text" class="btn d-none-sm"><router-link to="/index">首页</router-link></a-button>
          <a-button type="text" class="btn d-none-sm">咨询</a-button>
          <a-button type="text" class="btn d-none-sm">沙龙</a-button>
          <a-button type="text" class="btn d-none-sm"><router-link to="/join-us">加入我们</router-link></a-button>
          <a-button type="text" class="btn d-none-sm"><router-link to="/introduce">辰汇App</router-link></a-button>
          <a-button type="text" class="btn d-none-sm"><router-link to="/privacy">用户协议</router-link></a-button>
          <router-link to="/consultant">
            <a-button class="btn icon" type="primary">
              <template #icon><SearchOutlined /></template>
            </a-button>
          </router-link>
        </a-space>
      </div>
    </container>

    <router-view/>

    <container class="bg-dark-50 text-white" style="padding: 20px 0;margin-top: 70px">
      <a-row align="middle">
        <a-col
          style="margin-bottom: 30px"
          :xxl="8"
          :xl="8"
          :lg="12"
          :md="24"
          :sm="24">
          <a-image
            :src="require('@/assets/image/logo/横板白色黑底.png')"
            :preview="false"
            :width="300"
          />
        </a-col>
        <a-col
          :xxl="16"
          :xl="16"
          :lg="12"
          :md="24"
          :sm="24"
        >
          <left-title main="联系我们" color="#ffffff" font-size="18px" style="margin-bottom: 10px" />
          <a-row :gutter="[48,16]">
            <a-col
              :xxl="12"
              :xl="12"
              :lg="24"
              :md="24"
              :sm="24"
              v-for="(address, index) in addressList"
              :key="index">
              <div style="display: flex;align-items: flex-start; margin-top: 10px; border-bottom: 1px dashed rgba(147,147,147,0.3);">
                <EnvironmentFilled style="font-size: 12px; margin-right: 8px;color: #b3b3b3;margin-top: 5px" />
                <p style="font-size: 14px; font-weight: bold; margin-right: 10px;white-space: nowrap">{{ address.city }}</p>
                <div>
                  <p class="" style="font-size: 12px;margin-top: 0">{{ address.address }}</p>
                  <p class="line-1" style="font-size: 12px">{{ address.phone }}</p>
                </div>
              </div>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </container>
    <container class="bg-dark text-white-50" padding="20px 0">
      <div class="flex-column-sm align-items-center" style="display: flex; justify-content: space-between">
        <p class="mb-0">© 版权所有 2021</p>
        <p class="mb-0">Design by Xiaoming</p>
        <a href="https://beian.miit.gov.cn/#/Integrated/index"><p class="mb-0">粤ICP备18112044号-2</p></a>
      </div>
    </container>
  </div>
</template>

<script>
import { PhoneFilled, MailFilled, SearchOutlined, EnvironmentFilled } from '@ant-design/icons-vue'
import LeftTitle from '@/components/common/LeftTitle'

export default {
  name: 'BasicLayout',
  // eslint-disable-next-line vue/no-unused-components
  components: { LeftTitle, PhoneFilled, MailFilled, SearchOutlined, EnvironmentFilled },
  data () {
    return {
      isFixed: false,
      addressList: [
      { city: '深圳', address: '广东省深圳市福田区深业上城写字楼A座22F' },
      { city: '香港', address: '香港特别行政区湾仔道165-171号乐基中心1602' },
      { city: '广州', address: '广州市海珠区琶洲大道178号欧派国际广场1110' },
      { city: '成都', address: '四川省成都市锦江区东大街芷泉段6号时代1号2901' },
      { city: '佛山', address: '广东省佛山市南海区桂城街道灯湖东路1号友邦金融中心一座1606B' },
      { city: '珠海', address: '广东省珠海市香洲区前河北路68号环宇城写字楼1507' },
      { city: '武汉', address: '湖北省武汉市江汉区新华路218号浦发银行大厦20F' },
      { city: '杭州', address: '浙江省杭州市上城区世包国际中心西塔楼1315' },
      { city: '上海', address: '上海市虹口区四川北路1318号盛邦国际大厦2602' },
      { city: '郑州', address: '河南省郑州市北龙湖中瑞国际B座307-2' },
      { city: '厦门', address: '厦门市思明区宝龙国际中心909' },
      { city: '太原', address: '太原市万柏林区千峰北路漪汾华苑北区1幢西商2号' },
      { city: '昆明', address: '昆明市西山区万达广场写字楼南塔47层4705室' },
      ]
    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll () {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // 滚动条偏移量
      this.isFixed = scrollTop > 45
    }
  }
}
</script>

<style lang="scss" scoped>

.top-bar-curve{
  position: relative;
  z-index: 2;
  min-height: 40px;
  padding: 8px 15px 12px 15px;
  color: #ffffff;
  font-size: 13px;

  &:after{
    background-color: #d9a259;
    position: absolute;
    top: 0;
    left: 0;
    width: 1500px;
    height: 100%;
    content: "";
    z-index: -1;
    transform: skewX(35deg);
    -moz-transform: skewX(35deg);
    -webkit-transform: skewX(35deg);
    -o-transform: skewX(35deg);
    -ms-transform: skewX(35deg);
  }

  @media only screen and (max-width: 991px){
    &:after{
      left: -50%;
    }
  }

  & .city{
    position: relative;

    &:after{
      top: 3px;
      content: "";
      position: absolute;
      right: -8px;
      height: 12px;
      background-color: rgba(255, 255, 255, 0.27);
      width: 1px;
    }
  }
}

.header {
  position: relative;
  z-index: 999;
  background: #ffffff;
  color: white;

  & .logo-box{
    margin-top: -40px;
    transition: all .2s linear;
    -webkit-transition: all .2s linear;
    -moz-transition: all .2s linear;
    -ms-transition: all .2s linear;
    -o-transition: all .2s linear;

    & ::v-deep .img{
      width: 160px;
    }
    & ::v-deep .logo{
      width: 230px !important;
    }
  }

  @media only screen and (max-width: 991px){
    & .logo-box{
      margin-top: 0;

      & ::v-deep .img{
        width: 120px !important;
      }
      & ::v-deep .logo{
        width: 230px !important;
      }
    }
  }

  &.fixed {
    position: fixed;
    top: 0;
    width: 100%;
    box-shadow: 0 0 5px 5px rgb(0 0 0 / 10%);

    & .logo-box{
      margin-top: 0;
    }

  }

  & .btn{
    margin: 0;
    font-weight: 600;
    height: 80px;
    padding: 25px 15px;
    width: auto;
    color: #555555;

    &.icon{
      margin: 16px 10px;
      padding: 0 15px;
      height: 30px;
    }

    &:hover{
      color: #FDC716;
    }
  }

  //& .title {
  //  font-weight: bold;
  //  color: #FDC716;
  //  letter-spacing: 2px;
  //  margin-bottom: 4px;
  //
  //  & .icon {
  //    font-weight: normal;
  //    margin-right: 8px;
  //  }
  //}

  & .sub {
    color: #bfbfbf;
    font-size: 12px;
  }

  .card {
    background-color: #FDC716;
    padding: 8px 25px;
    text-align: center;
    border-radius: 3px;

    & .title {
      color: #ffffff;
    }

    & .sub {
      color: #ffffff;
    }
  }
}

.nav {
  & > .container {
    position: absolute;
    width: 100%;
    z-index: 1000;

    & .btn{
      padding: 0 20px;
      height: 60px;
      width: auto;
      color: white;

      &:hover{
        color: black;
      }
    }
  }
}
</style>
